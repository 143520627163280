.multiselect-dropDown {
    .material {
        .form-label {
            top: -1px !important;

            &::before {
                position: inherit;
                color: $heading-color;
                font-size: 12px;
            }
        }

        &.active-label {
            .form-label {
                top: -19px !important;

                &::before {
                    background-color: $white;
                }
            }
        }

        .css-1u9des2-indicatorSeparator {
            display: none;
        }
    }
}

.sorting-filter-cls {
    margin-right: 20px;
    width: 200px;
}

.single-select {
    .css-1u9des2-indicatorSeparator {
        display: none;
    }

    .css-13cymwt-control {
        border-radius: 8px;
        border-color: #bbbbbb !important;

        &:hover {
            border-color: #bbbbbb !important;
            box-shadow: none;
            border-radius: 8px;
            outline: none !important;
        }
    }

    .css-t3ipsp-control {
        border-color: #bbbbbb !important;
        outline: none !important;

        &:hover {
            border-color: #bbbbbb !important;
            box-shadow: none;
            border-radius: 8px;
            outline: none !important;
        }
    }

}

.status-btn {
    display: flex;

    .status-txt {
        margin-left: 10px;
    }
}

.role-status-outer {
    display: flex;
    align-items: center;
}

.user-managment-table-data {
    table {
        tr {

            th,
            td {
                &:nth-child(3) {
                    width: 15%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                &:nth-child(6) {
                    width: 15%;
                }
                &:nth-child(8) {
                    width: 5%;
                }
                &:nth-child(9) {
                    width: 5%;
                }
                
            }
        }
    }

    tbody {
        tr {
            td {
                height: auto;
            }
        }
    }
}

.role-managment-table-data {
    table {
        tr {

            th,
            td {
                &:nth-child(2) {
                    width: 10%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                height: auto;
            }
        }
    }
}

.common-appliction-outer {
    display: flex;
    align-items: center;
}

.insurance-doc-upload-outer {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .insurance-doc-upload {
        width: 136px;

        .upload-image-input {
            opacity: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }

        .add-new-image {
            margin: 15px 0px 8px !important;
        }

        .doc-tag-name {
            margin: 0px !important;
        }
    }
}

.assign-user-tab {
    .tab-list {
        padding-left: 0px !important;
    }
}

.assign-radio-btn {
    align-items: center;
    padding-top: 20px;

    .form-filed {
        padding-top: 0px;
    }

    .material {
        margin-top: 0px;
    }
}

.align-center {
    align-items: center;
}

.add-role-btn {
    position: absolute;
    right: 20px;
    top: 14px;
}

.height-auto {
    tbody {
        tr {
            td {
                height: auto !important;
            }
        }
    }
}

.managepanel-tab {
    .view-timeline-popup {
        tbody {
            tr {
                td {
                    height: auto;
                    vertical-align: middle;

                    &:nth-child(3) {
                        width: auto;
                    }
                }
            }
        }
    }
}

.insurnace-pop-select {
    .single-select {
        width: 100%;
        position: relative;
        z-index: 999;
    }
}

.date-picker-detail {
    .material {
        &.active-label {
            .form-label {
                top: -20px !important;

                &::before {
                    background-color: $white;
                }

            }
        }
    }
}

.quote-detail-form {
    .single-select {
        width: 33.33%;
        margin-left: 20px;
    }
}

.premium-txt {
    padding-top: 10px;
}

.insure-make-varint {
    display: flex;
    align-items: top;
    padding: 20px 0;
    border-bottom: 1px solid $border-color;
    width: calc(100% + 45px);
    margin-left: -30px;
    padding-left: 25px;
    padding-right: 15px;
    padding-bottom: 20px;

    .insurance-img {
        width: 128px;
        height: 57px;
        border: 1px solid rgba(224, 224, 224, 1);
        background: rgba(255, 255, 255, 1);
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px;
    }

    .insure-varint-make-detail {
        margin-left: 20px;

        span {
            font-family: Poppins;
            font-size: 11px;
            font-weight: 400;
            line-height: 17px;
        }

        ul {
            display: flex;
            align-content: center;
            padding-top: 10px;

            li {
                font-family: Poppins;
                font-size: 11px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0px;
                text-align: left;

                &::after {
                    content: '';
                    height: 4px;
                    width: 4px;
                    background: rgba($heading-color, 0.5);
                    display: inline-block;
                    top: -1px;
                    position: relative;
                    border-radius: 100%;
                    margin: 0px 10px;
                }

                &:last-child {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}

.insurance-detail-outer {
    ul {
        display: flex;
        align-items: top;
        margin-left: -10px;
        margin-right: -10px;
        flex-wrap: wrap;
        margin-bottom: 20px;

        li {
            width: calc(22% - 20px);
            padding: 0px 10px;
            margin: 20px 0px 10px;

            span {
                font-size: 11px;
                color: rgba($heading-color, 0.6);
                display: block;
            }

            label {
                font-size: 13px;
                font-weight: normal;
            }
        }
    }
}

.insurace-quote-heading {
    background: #E3E7F1;
    width: calc(100% + 60px);
    margin-left: -30px;
    margin-right: -30px;
    padding: 15px 30px;
}

.add-remarks-filed {
    margin-bottom: 0px !important;
}

.remarks-history-popup {
    .timeline-history-tabel {
        max-height: 210px;
    }
    table{
        tr{
            background: transparent !important;
            border: 0px;
            td{
                &:nth-child(2){
                    background: #eff2f9;
                }
            }
            &:hover{
                td{
                    background: #bff8fc;
                }
            }
        }
    }
}

.loan-form-filed-scroll {
    .loan-filed-sec {
        .insurance-image-uplaod-view {
            width: auto !important;
            margin: 0px -10px 12px;
            display: flex;
            .document-image-container {
                margin: 0 8px 10px;
                height: 200px;
                width: 160px;
                padding: 0px 10px;
                

                .add-new-image {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 8px;
                    width: 160px;
                    height: 140px;
                    background: #f4f6fb;
                    border-radius: 10px;
                    position: relative;
                    overflow: hidden;
                }
                .doc-tag-name{
                    text-align: center;
                }

                .error-txt {
                    bottom: 0;
                }
            }

        }
            .insurance-image-uplaod-view-err {
                margin: 0px -10px 12px;
            
            }
    }
  
}

.insurance-doc-upload-main{
    display: block !important;
    .doc-close-icn{
     position: absolute; 
     top: 0px;
     right: 0px;
     height: 20px;
     width: 20px;
     background: rgba($heading-color, 0.5);
     border-radius: 100%;
     color: #fff;
     display: flex;
     align-items: center;
     justify-content: center;
     .ic-clearclose{
        font-size: 15px !important;
     } 
    }
}

.upload-input-bx{
    display: block !important;
    padding-top: 33px !important;
    .upload-image-input{
        top: 0px;
        left: 0px;
    }
    .ic-add{
    display: block;
    }
}

.share-data-lisy {
    display: block;
    line-height: normal !important;
    border-bottom: 1px solid $border-color;
}
.lead-form-sub-heading{
    h3{
        padding-top: 0px;
    }
}

.insurance-create-outer {
    .loan-expect-left-panle {
        .loan-form-filed-scroll{
            .check-box-small {
                .custom-control-input {
                    left: 9px;
                }

            }
        }
    }
} 
.add-role-popup{
    .modal-main{
        width: 480px;
        .form-filed{
            padding-top: 0px;
            margin-bottom: 20px;
        }
        .modal-body{
            padding-top: 4px;
        }
        .material{
            margin-top: 0px;
        }
    }
    .btn-primary{
        margin-top: 0px;
    }
}
.user-managent-add-user-popup{
    h2{
        padding-bottom: 5px !important;
    }
    .modal-body{
        padding-top: 0px !important;
    }
}
.insurance-top-filer-heading{
    .btn-line {
    .ic-add{
        margin-left: 5px;
    }
}
}
.m-b-10{
    margin-bottom: 10px;
}
.br-bottom{
    border-bottom: 1px solid $border-color;
    width: calc(100% + 60px);
    margin-left: -30px;
    padding-left: 30px;
    margin-right: -30px;
    padding-right: 30px;
    margin-bottom: 20px;
}
.check-box-small {
.checkbox-label-txt{
    font-weight: 500;
    font-size: 16px;
    color: $heading-color;
    span{
        position: relative;
        top: -3px;
    }
    
}
}
.common-application-checkbox{
    margin-bottom: 10px !important;
    margin-top: 10px !important;
}
.culcated-premimum-txt{
    label{
        color: $heading-color;
        font-size: 16px;
        font-weight: 500;
        line-height: 30px; 
        display: block;
    }
    span{
        color: $heading-color;
        font-size: 13px;
        line-height: normal;
    }
}

.insurance-create-outer {
.loan-filed-sec{
    margin-bottom: 0px;
}
}
.f-right{
    float: right;
}
.t-right{
    text-align: right;
}
.doc-uplaod-heading{
    h3{
        border-bottom: 1px solid $border-color;
        color: $heading-color;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        padding-bottom: 10px;
        margin-top: 10px;
    }
}
.doc-attech-heading{
    h3{
        color: $heading-color;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        padding-bottom: 10px;
    }
}
.loan-filter-bg{
    .role-management-top-sec{
        padding: 0px !important;
        border-radius: 8px;
        .tab-line{
            .tab-list{
                border-radius: 8px !important;
            }
            &.nav-pills{
                border-radius: 8px;
                .nav-item{
                    a{
                        font-size: 16px;
                    }
                }
            }
        }

        .tab-line {
            &.nav-pills {
                width: 100%;
                margin-left: 0px;
                padding: 0px;
                box-shadow: none;
                background: transparent;
                .nav-item {
                    a {
                        background: transparent;
                        padding: 0px;
                        height: auto;
                        border-bottom: 2px solid transparent;
                        border-radius: 0px;
                        color: rgba($heading-color, 0.5);
                        margin-right: 35px;
                        padding-bottom: 3px;
                        font-size: 14px;
                        &.active {
                            border-color: #0bbddd;
                            color: #0bbddd;
                            font-weight: 500;
                        }
                    }
                    &:last-child {
                        a {
                            margin-right: 0px;
                        }
                    }
                }
                .tab-list {
                    position: relative;
                    // width: 92%;
                    overflow: auto;
                    &:after {
                        content: "";
                        background: rgba(0, 0, 0, 0.1);
                        height: 1px;
                        width: 100%;
                        position: absolute;
                        bottom: 0px;
                    }
                }
                .config-name-row {
                    width: 92%;
                    overflow-x: scroll;
                    white-space: nowrap;
                }
                ::-webkit-scrollbar {
                    height: 0px;
                    width: 0px;
                }
            }
        }
    
    }
}

.managment-tabs{
    .lead-list-data-table{
        border-radius: 8px;
        margin-top: 20px;
        table{
            border-radius: 8px;
        }
    }
}
.user-management-inner-tabs{
    margin-top: -10px;
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-bottom: 10px !important;
    position: relative;
    z-index: 1;
    .flex-column {
        .tab-list{
            padding-top: 13px !important;
        }
    }
    .nav-pills {
        .nav-item{
            a{
                white-space: nowrap;
            }
        }
    }
    .search-user-add-btn{
        display: flex;
        align-items: center;
        .search-bx{
            width: 320px;
            margin-bottom: 0px !important;
            margin-right: 10px;
            .material .form-input {
                height: 42px;
            }
        }
        
    }
}
.upload-doc-list-acc{
    .accordion__item{
        border-bottom: 1px solid $border-color !important;
        label{
            color: $heading-color;
        }
    }
    .sub-list{
        ul{
            li{
                line-height: 30px;
                label{
                    font-size: 13px;
                    color: rgba($heading-color, 0.7);
                    display: inline-block;
                }
            }
        }
    }
}
.insurance-broker-doc-attech-list{
    .sub-list{
        ul{
            li{
                padding-top: 5px !important;
                .custom-control{
                margin-top: 0px !important;
                }
            }
        }
    }
}
.edit-user-popup-bx{
    .password-txt-msg{
        .error-txt {
            position: initial;
        }
       
    }
}
.doc-upload-hight-outer{
    max-height: initial !important;
    .loan-filed-sec {
        margin-bottom: 20px;
    }
}

.user-status-tab-chips .active span.chip-count-span {
    background: rgba(0,151,178,.6);
    color: #fff;
}
.user-status-tab-chips  span.chip-count-span {
    background: rgba(227,231,241,.6);
    padding: 3px 8px;
    margin-left: 10px;
    font-size: 11px;
    border-radius: 12px;
}

.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }

.more-filter-form {
    display: flex;
    justify-content: space-between;

    fieldset {
        width: 215px !important;

        &.multi-datepicker {

            .rmdp-container {
                width: 100%;
            }
            input {
                width: 100%;
                border: 1px solid #bbbbbb;
                border-radius: 8px;
                box-shadow: 0 0 0;
                padding: 11px 15px;
                font-weight: 400;
                font-size: 12px;
                background: url(../../images/date.svg) no-repeat right 7px center;
            }
            .rmdp-input {
                height: auto !important;
            }

            .react-datepicker__header__dropdown {
                padding: 10px;

                select {
                    appearance: auto;
                    background: transparent;
                    border: 0px;
                }
            }
        }


    }
}  

.add-insurance-partner{
    background: transparent;
    border: 1px solid #bbbbbb;
    // height: 100% !important;
    margin-top: 10px;
    
    .upload-icn{
        height: auto;
        margin-right: 10px;
        max-height: 100%;
    }
    input[type=file]{
        // height: 100% !important;
        top: 10px !important;
    }
}

.insuranc-partner-accordion{
    margin-top: 25px;
    .accordion__heading{
        background: #e3e7f1;
        border-radius: 8px;
        padding: 10px;
        .accordion__button{
             display: flex;
              justify-content: space-between;
              align-items: center;
             
        }
    } 
    .on-off-delete-icn{
        display: flex;
        align-items: center;
        position: absolute;
        right: 40px;
        top: 13px;
        z-index: 1;
      }

    fieldset  {
        margin-bottom: 12px;
    }

}
.btn-width-auto-bank-details{
    min-width: auto;
    display: flex;
    align-items: center;
    .ic-add{
        font-size: 20px;
    }
}


.agency-Partner-list{
    table{
        tr{
            th,td{
                height: auto;
                &:last-child{
                    width: 5%;
                }
                &:nth-child(5){
                    width: 8%;
                }
                &:nth-child(6){
                    width: 20%;
                }
            }
        }
        tbody{
            tr{
                &:hover{
                    .action-btn{
                        li{
                            a{
                                color: $white;
                            }
                        }
                    }
                }

                td{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
    .more-txt{
        font-size: 12px;
        color: $heading-color;
        text-decoration: underline;
        margin-left: 5px;
    }
}
.add-basic-doc{
    .image-bx{
        height: auto !important;
        background: #EFF2F9;
        border: 0px;
        margin-bottom: 15px !important;
        .ic-add{
             font-size: 25px;
        }
    }
    .document-image-container {
        width: 100%;
        text-align: center;
        padding: 30px 0px;
        .upload-input-bx{
            padding-top: 0px !important;
        }
    }
}
.br-left{
    border-left: 1px solid $border-color;
}
.edit-approve-btn-top{
    .btn-line, .btn-primary{
        min-width: auto;
         padding: 0px 30px;
    }
}

.image-container-agency {
    background: #EFF2F9;
    border-radius: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 126px;
    margin-top: 10px;
    position: relative;

    .close-icn {
        position: absolute;
        top: 5px;
        right: 5px;
        opacity: 0;
        .ic-clearclose {
            background: rgba(31, 31, 31, 0.6);
            height: 16px;
            width: 16px;
            border-radius: 100%;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 11px;
        }
    }
    &:hover{
        .close-icn {
            opacity: 1;
        } 
    }
    .ic-pdf-file{
        font-size: 100px;
    }
    
}


.add-agency-form {
    .material{
        margin-bottom: 8px;
    }
}

.resubmit-btn {
 .btn-line{
    min-width: auto;
    padding: 0px 30px;
 }
}

.tin-document-popup {
    .modal-main{
        width: 650px;
        // min-height: 760px;
    }
   .arrow-silder {
    position: absolute;
    top:35%;
    transform: translateY(-50%);
    background: #ffffff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 20px;
    cursor: pointer;
   }
   .arrow-next{
    right: 0px;
   }
   .arrow-prev{
    left: 0px;
   }
   .img-bx-select-opt{
    text-align: center;
   }
   .reset-width-height {
    width: auto !important;
}
   img {
    // width: 35%;
    width: 100vw;
    height: 38vh;
    object-fit: contain;
   }
   .image-status {
    top: 14px;
    left: 170px
   }
   .image-requested {
    top: 14px;
    left: 170px
   }
   .ic-pdf-file{
        font-size: 200px;
    }
}

.agency-create-flow{
    .remarks-history-popup {
        z-index: 99;
    }
}

.insurance-list {
    ul{
        max-height: 500px;
        overflow-y: auto;
        li{
            border-bottom: 1px solid $border-color;
            padding: 15px 0px;
            &:last-child{
                border-bottom: 0px;
            }
            
        }
        
    }
}

.btn-bottom{
    border-top: 1px solid $border-color;
    padding-top: 20px;
    margin-top: 20px;
}

.silder-action-button {
    .btn-line{
        min-width:auto;
        padding: 0px 30px;
    }
}

.br-top{
    border-top: 1px solid $border-color;
    
    margin-top: 10px;
    .insurance-list , .add-agency-form{
        padding-top: 20px;
    }
}

.m-t-0 {
    margin-top: 0px !important;
}

.bulk-payment-table {
    table {
        tr {
            th,td{
                &:last-child {
                    width: 4%;
                }
            }
        }
    }
}

.more-text {
    font-size: 12px;
    font-weight: 500;
    color: $heading-color;
    text-decoration: underline;
    margin-left: 10px;
    cursor: pointer;
}


.search-insurance-id-outer {
    background: #EEF6FF;
    border-radius: 20px;
    padding: 20px;
    margin-top: 30px;
    h3 {
        font-size: 16px;
        font-weight: 500;
        color: $heading-color;
        padding-bottom: 15px;

    }

    // .search-multiselect {
    //     display: block;
    //     width: 100%;
    //     .material {
    //         width: 33.33%;
    //     }
    // }


}

.insurance-id-list {
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border: 1px solid #E7E7E7;
    position: relative;

    .close-icn {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .detail-header {
        height: 42px;
    }
}

.upload-heading-text {
    background: #D8EAFF;
    border-radius: 8px;
    padding: 10px 20px;
    margin-top: 30px;

    h3 {
        font-size: 16px;
        font-weight: 500;
        color: $heading-color; 

    }
}

.add-document-proof {
    background: #F4F6FB ;
    height: 150px;
    border-radius: 6px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    input {
        opacity: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .ic-add {
        font-size: 30px;
        color: $heading-color;
    }
}

.upload-image-proof {
    background: #F4F6FB ;
    height: 150px;
    border-radius: 6px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; 
    position: relative;
    overflow: hidden;

    .close-icn {
        position: absolute;
        top: 0;
        right: 0;
        background: #666666;
        border-radius: 16px;
        height: 16px;
        width: 16px;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center; 
        cursor: pointer;
    }
}

.bulk-payment-tooltip-outer {
    .user-detail {
        li {
            span {
                width: 100% !important;

            }
        }
    }
}


.agency-payment-form {
    .date-picker-detail  {
        
        .material.active-label {
            .form-label {
                width: 63%;   
            }
        }
        
    }

    .datepicker {
        label {
            width: 63%;   
        }
    }

    .form-label {
        width: 43%;
    }
}

.error-txt {
    position: relative;
    bottom: 0;
}

.image-requested {
    background: #c03b5a;
    position: absolute;
    top: 3px;
    left: -13px;
    color: #fff;
    font-size: 12px;
    padding: 1px 10px;
    transform: rotate(-28deg);
    width: 103px;
    text-align: center;
  }

  .error-txt-partner {
    position: absolute;
    bottom: -20px;
    color: #ff2e2e;
    font-size: 10px;
    left: 0;
  }

  .m-t-20 {
    margin-top: 20px !important;
  }

  .m-t-25 {
    margin-top: 25px;
  }