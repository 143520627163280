
.tab-content {
    .tab-pane {
        display: none;
        &.active {
            display: block;
        }
    }
}

.lead-detail-tabs {
    padding: 16px 0;
    .menu-tab-left-panel {
        width: 26%;
        background: $white;
        padding: 25px 33px;
        position: relative;
        ul {
            li {
                position: relative;
                a {
                    color: rgba(0, 0, 0, 0.5);
                    margin-bottom: 20px;
                    display: inline-block;
                    width: 100%;
                    .img-type {
                        background: #DEE5EF;
                        border: 2px solid #DEE5EF;
                        height: 10px;
                        width: 10px;
                        margin-top: 5px;
                        margin-left: -15px;
                        margin-right: 15px;
                        vertical-align: top;
                        display: inline-block;
                        position: relative;
                        border-radius: 50%;
                        z-index: 2;
                    }
                    
                    &.active {
                        color: $heading-color;
                        .img-type {
                            background: #DEE5EF;
                            border: 2px solid #008CF0;
                            position: relative;
                        }
                    }
                    &.completed {
                        color: $heading-color;
                        .img-type {
                            background: #19B657;
                            border: 2px solid #19B657;
                            position: relative;
                        }
                    }
                }
                
                &:last-child {
                    &:after {
                        display: none;
                    }
                }
                
            }
        }
     
    }
  
  
    .detail-form-sec {
        display: flex;
    }
}
h3{
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color:$heading-color;
    }
.lost-btn {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $white;
    background: #ff8a8a;
    border-radius: 4px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 13px;
    border: 1px solid #ff8a8a;
    cursor: pointer;
}

.route-files {
    width:calc(100% - 26%);
    padding-left: 20px;
}

.form-tab-right-panel{
    background: $white;
    padding: 30px;
    margin-top: 20px;
}

.link-hyperlink {
    a {
        position: absolute;
        left: 0;
        top: 14px;
        padding: 0px 15px;
    }
    span {
        position: absolute;
        left: 0;
        top: 14px;
        padding: 0px 15px;
    }
    .form-label:before {
        top: -9px !important;
        background: $white;
    }
}

button.lost-btn {
    &.btn-cancel-do {
        position: absolute;
        right: 20px;
        background: #ffa340;
        border: 1px solid #ffa340;
    }
}

.relative {
    position: relative;
}

.btn-continue{
    border-top: 1px solid rgba($heading-color, 0.1);
    text-align: center;
    padding: 30px 30px 0;
    width: calc(100% + 60px);
    margin: 25px -30px 0px;
    display: inline-block;
    .btn-primary{
        min-width: 275px;
    }
}

.lead-top-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.doc-upload-main{
    .doc-heading{
        background: #E1E8F3;
        border-radius: 8px;
        font-size: 13px;
        font-weight: 500;
        line-height: 19.5px;
        text-align: left;
        padding: 10px 20px;
        color: $heading-color;
        display: block;
        width: 100%;

    }
}

// .close-icn {
//     position: absolute;
//     top: 5px;
//     right: 5px;
//     opacity: 0;
//     .ic-clearclose {
//         background: rgba(31, 31, 31, 0.6);
//         height: 16px;
//         width: 16px;
//         border-radius: 100%;
//         color: #fff;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         font-size: 11px;
//     }
// }
.img-bx-select-opt {
    position: relative;
    margin: 15px 17px 10px;
    width: calc(20% - 34px);
    .image-bx {
        height: 140px;
    }
    &:hover {
        .close-icn {
            opacity: 1;
            cursor: pointer;
        }
    }
    .custom-control-label {
        display: flex;
    }
    .image-bx {
        background: #f4f6fb;
        border-radius: 6px;
        padding: 10px;
        height: 114px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 140px;
        overflow: hidden;
        img{
            background-size: cover;
        }
    }
}
.more-file-upload {
    flex-direction: column;

    p {
        display: block;
        font-size: 11px;
        color: rgba($heading-color, 0.7);
        text-align: center;
    }
    input[type="file"] {
        opacity: 0;
        height: 140px;
        width: 100%;
        outline: 0;
        z-index: 1;
        position: absolute;
        top: 0;
        cursor: pointer;
    }
    .ic-add{
        font-size: 30px;
    }
   
}
.video-play-icn{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    left: 0;
    right: 0;
}
.upload-doc-img{
    display: flex;
    align-items: center;
flex-wrap: wrap;
margin: 0px -17px;
margin-bottom: 20px;
}
.ask-coustmer-btn{
    min-width: auto;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    i{
        margin-right: 5px;
        font-size: 16px;
    }
}


[hidden] {
    display: none;
}



.send-insurnace-doc-list{
.accordion__button::after {
    // position: absolute;
    content: "\e917";
    // right: 20px;
    font-size: 25px;
    font-family: "dc" !important;
    color: $heading-color;
}

.accordion__button[aria-expanded='true']::after,
.accordion__button[aria-selected='true']::after {
    content: "\e91a";   
    font-family: "dc" !important;
}
.accordion{
    position: relative;
}

.accordion__button {
    background: transparent;
    border-radius: 8px;
    padding: 0px 0px;
    display: flex;
    align-items: center;

    h3 {
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        color: $heading-color;
        padding: 20px 0;
    }
}
.accordion__item{
    margin-bottom: 15px;
}

.accordion__panel {
    animation: fadein 0.35s ease-in;
    padding-left: 30PX;
}
.attech-doc-list{
    background: #ECF1F8;
    display: inline-block;
    width: 100%;
    border-radius: 8px;
    .custom-control-label::before{
        background:rgba($white, 0.54)
    }
    .custom-control-input:checked ~ .custom-control-label::before {
        color: #fff;
        border-color: $accent-color;
        background-color: $accent-color;
      }
}

}

.radio-type-txt{
    align-items: center;
    .form-label{
font-size: 14px;
font-weight: 500;
line-height: 30px;
text-align: left;
color: $heading-color;
margin-right: 15px;
    }
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
    width: 70px !important;
}
.react-datepicker__time-container{
    width: 70px !important;
}

.estimate-detail-sec {
    display: inline-block;
    width: 100%;
    border-top: 1px solid $border-color;
    padding: 40px 30px 0;
    width: calc(100% + 60px);
    margin: 20px -30px 0px;


h3{
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: $heading-color;
    padding-bottom: 20px;

}

.attech-doc-view{
    border: 1px solid #C1C1C1;
    background: $white;
    color: $heading-color;
    i{
        color: $heading-color;
    }
    p{
        color: $heading-color;
        cursor: pointer;
    }
    .ic-cancel{
        position: absolute;
    right: 0px;
    }
}
.btn-line{
    min-width: auto;
    width: 100%;
    height: 54px;
    font-size: 13px;
    i{
        margin-right: 5px;
        font-size: 16px;
    }
}
}

.Evaluation-Letter-popup{
    .modal-main{
        width: 580px;
    }
}

.attech-repair-doc{
    border: 1px solid $accent-color;
    color: $accent-color;
    min-width: 105px;
    background: rgba($accent-color, 0.05);
    display: inline-flex;
    align-items: center;
    height: 54px;
    border-radius: 8px;
    width: 100%;
    position: relative;
    justify-content: center;
    cursor: pointer;
    p{
        font-size: 13px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        color: $accent-color;

    }
    i{
        color: $accent-color;
        margin-right: 5px;

    }
    input[type="file"] {
        opacity: 0;
        height: 54px;
        width: 100%;
        outline: 0;
        z-index: 1;
        position: absolute;
        top: 0;
        cursor: pointer;
    }
    
}

.add-repait-doc-txt{
    font-size: 13px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: $accent-color;
    display: flex;
    align-items: center;
    cursor: pointer;
    i{
        margin-left: 5px;
        font-size: 16px;
    }
}

.yes-no-option{
    ul{
        display: flex;
        align-items: center;
        padding-bottom: 40px;
        padding-top: 15px;

        li{
            box-shadow: 0px 3px 4px 0px #0000000A;
            border: 1px solid #DCDCDC;
            width: 180px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            color: $heading-color;
            border-radius: 10px;
            margin-right: 10px;
            cursor: pointer;
            i{
                margin-right: 5px;
                font-size: 20px;
            }
            &.active{
                border-color: $accent-color;
                color: $accent-color;
            }
            .no-active{
                border-color: #E43232;
                color: #E43232;
            }
        }
    }
}


.car-release-radio{
   .form-label {
        font-size: 13px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        color: $heading-color;

    }
}

.attach-loa{
    width:100%;
    height:54px;
}

.border-color-red {
    border-color: red !important;
    color: red !important;
}

.video-icon-size {
    height: 100%;
}

.detail-video-icon-size {
    height: 50%;
}

.react-datepicker-popper{
    z-index: 9 !important;
}

.loa-button-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    border-bottom: 1px solid lightgray;
    padding-bottom: 20px;
    span {
        font-size: 16px;
        color: black;
    }
}