.material {
  position: relative;
  .form-input {
    border: 1px solid #bbbbbb;
    border-radius: 8px;
    box-shadow: 0 0 0;
    width: 100%;
    padding: 22px 15px 10px;
    font-weight: 500;
    font-size: 13px;
    // margin-bottom: 20px;
    &:focus {
      ~ label {
        &:before {
          top: 9px;
          font-size: 11px;
        }
      }
    }
    &:not(:placeholder-shown) {
      ~ label {
        &:before {
          top: 9px;
          font-size: 11px;
        }
      }
    }
    
  }
  textarea{
    height: 54px;
    resize: none;
  }
  .form-label {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    &:before {
      content: attr(data-label);
      position: absolute;
      left: 13px;
      top: 16px;
      @include transition(all 0.15s ease-in-out);
      color: $text-color;
      font-size: 13px;
      padding: 0 3px;
      white-space: nowrap;
      background-color: #fff;
    }
  }

  .search-label {
    margin-left: 30px;
  }

  .datepick-heading {
    &:before {
        // top: -9px !important;
        font-size: 12px;
        background: #ffffff;
    }
}
}

.search-bx {
  position: relative;
  input {
    background: $white;
    border: 1px solid $text-color;
    border-radius: 8px;
    width: 100%;
    padding: 15px 15px 15px 50px;
    font-size: 13px;
  }
}

/*the container must be positioned relative:*/
.custom-select {
  position: relative;
  font-family: Arial;
}

.custom-select select {
  display: none;
  /*hide original SELECT element:*/
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-28%);
  right: $spacing-lg;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #000 transparent transparent transparent;
  transition: all 0.1s;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent $text-color transparent;
  transform: translateY(-95%);
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  padding: 14px $spacing-lg;
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid rgba($border-color, 0.1);
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: #fff;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  color: $text-color;
  border: 1px solid $border-color;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
  max-height: 225px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
