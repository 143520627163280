.lead-list-outer{
    margin: 15px 0;
}
.list-filter-notification {
    background: $white;
    padding: 30px 20px;
    width: 304px;
    border-radius: 10px;

    .filter-outer {
        h2 {
            font-size: 16px;
            font-weight: 500;
            line-height: 30px;
            text-align: left;

        }
        .radio-type-txt{
            label{
                font-size: 13px;
                color: $heading-color;
                padding-bottom: 10px;
                display: block;
            }
        }
        .custom-checkbox{
            margin-right: 30px;
        }
    }
    .claim-notification-outer{
        width: 100%;
        padding: 50px 0px 0;
        h2{
            font-size: 16px;
            font-weight: 500;
            line-height: 30px;
            text-align: left;
            padding-bottom: 10px;
        }
        .notification-list-content{
            background: #F0F4F9;
             border-radius: 10px;
            padding: 30px 20px;
            background: #F0F4F9;

        }
        
    }
}
.full-width{
    .btn-primary{
        width: 100%;
        min-width: auto;
    }
}

.search-bx {
    .material{
        .form-input {
            padding: 10px 15px 10px 45px;
        }
        i {
            font-size: 20px;
            left: 15px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
} 

.claim-list-rigt-sec{
    width: calc(100% - 304px);
    padding-left: 20px;
    .insurance-claim-list-outer{
        .search-bx{
            width: 500px;
        }
        background: $white;
        border-radius: 10px;
        padding: 20px 0;
        margin-top: 20px;
        height: calc(100% - 41px);
        .btn-right{
            i {
                font-size: 16px;
                padding-right: 5px;
            }
            .btn-line, .btn-primary {
                align-items: center;
                display: flex;
                padding: 0 20px;
                min-width: auto;
            }
          
        }
        .search-panel-btn{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 20px 20px;
        }
        table{
            tr{
                th,td{
                    &:first-child{
                        width: 9%;
                    }
                    &:nth-child(2){
                        width: 11%;
                    }
                    &:nth-child(3){
                        width: 14%;
                    }
                    &:nth-child(4){
                        width: 12%;
                    }
                    &:nth-child(5){
                        width: 8%;
                    }
                    &:nth-child(7){
                        width: 13%;
                    }
                    &:nth-child(8){
                        width: 13%;
                    }
                }
                td{
                    vertical-align: top;
                }
            }
        }
    }
   
}
.add-application-popup{
    .modal-main{
        width: 1036px;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
        width: 65px !important;
    }
    .react-datepicker__time-container{
        width: 65px !important;
    }

    .react-datepicker__month-container {
        width: 228px !important;
    }
    
}

.searhc-list-outer{
    box-shadow: 0px 2px 14px 0px #1F2D4E38;
    background: $white;
    padding: 30px 25px;
    border-radius: 10px;
    position: absolute;
    width: 600px;
    z-index: 9;
    max-height: 340px;
    overflow-y: auto;
    ul{
        li{
            border-bottom: 1px solid $border-color;
            padding: 12px 0px;
            label{
                font-size: 13px;
                font-weight: 500;
                color: $heading-color;
                display: block;
                padding-bottom: 5px;
            }
            .insurance-detail-list {
                display: flex;
                align-items: center;
                font-size: 11px;
                font-weight: 400;
                color: $heading-color;
                margin:0px -10px;
                span{
                    padding: 0px 10px;
                    position: relative;
                    &::after{
                        content: "";
                        height: 3px;
                        width: 3px;
                        border-radius: 5px;
                        background: $heading-color;
                        position: absolute;
                        right: -1px;
                        top: 7px;
                    }
                    &:last-child{
                        &::after{
                            display: none;
                        }
                    }
                }

                .search-span-one {
                    width: 25%;
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .search-span-two {
                    width: 15%;
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .search-span-three {
                    width: 25%;
                }
                .search-span-four {
                    width: 40%;
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            &:first-child{
                padding-top: 0px;
            }
            &:last-child{
                border-bottom: 0px;
            }

        }
        li :hover {
            cursor: pointer;
        }
    }
}

.table-select-box__placeholder{
    white-space: nowrap;
}
.select-table, .table-select-box{
    .single-select{
        width: 105px;
    }
}
.amt-edit-opt{
    display: flex;
    align-items: center;
    .btn-primary{
        min-width: auto;
    font-size: 10px;
    height: auto;
    padding: 5px 10px;
    line-height: normal;
    margin-top: 3px;
    display: flex;
    align-items: center;
    }
    .ic-clearclose{
        font-size: 18px;
        cursor: pointer;
        margin-left: 5px;
    }
}

.add-insurnace-search-outer {
    .search-bx {
        .material{
            .form-input {
                padding: 22px 45px 10px;
            }
        }
    }
}

.css-19bb58m:after {
    padding: 5px 0 0 !important;
}


.react-datepicker__navigation--previous {
    top: 12px !important;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button){
    right: 65px !important;
    top: 11px !important;
}

.react-datepicker__navigation--next {
    top: 10px !important;
}

.react-datepicker__current-month {
    text-align: center !important;
}

.view-timeline-popup {
    table {

        tr {

            td:nth-child(3) {
                width: 100% !important;
            } 
        } 
    }
}


.react-datepicker {
    display: flex !important;
}