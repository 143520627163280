.rule-engine-menu-sec {
    background: $white;
    border-radius: 8px;

    .col-md-3 {
        border-right: 1px solid rgba($heading-color, 0.1);
    }

    .col-md-5 {
        border-right: 1px solid rgba($heading-color, 0.1);
    }

    .menu-list {
        padding: 35px 40px;

        height: 100%;

        .menu-icn {
            background: #eff2f9;
            height: 66px;
            width: 66px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            margin-bottom: 20px;
        }

        h2 {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: $heading-color;
            display: block;
            padding-bottom: 12px;
        }

        ul {
            li {
                position: relative;
                padding-left: 20px;

                a {
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 40px;
                    color: rgba($heading-color, 0.7);
                    cursor: pointer;
                }

                &::before {
                    content: "";
                    background: rgba($heading-color, 0.3);
                    width: 5px;
                    height: 5px;
                    border-radius: 5px;
                    position: absolute;
                    left: 0;
                    /* bottom: 0px; */
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
    
}

.list-table-rigt-sec {
    padding-left: 20px;

    .insurance-claim-list-outer {
        .search-bx {
            width: 400px;
            flex: right;
        }

        background: $white;
        border-radius: 10px;
        padding: 20px 0;
        margin-top: 20px;
        height: calc(100% - 41px);

        .btn-right {
            i {
                font-size: 16px;
                padding-right: 5px;
            }

            .btn-line,
            .btn-primary {
                align-items: center;
                display: flex;
                padding: 0 20px;
                min-width: auto;
            }

        }

        .search-panel-btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 20px 20px;
        }

        table {
            tr {

                th,
                td {
                    &:first-child {
                        width: 10%;
                    }

                    &:nth-child(2) {
                        width: 10%;
                    }

                    &:nth-child(3) {
                        width: 25%;
                    }

                    // &:nth-child(4) {
                    //     width: 16%;
                    // }

                    &:nth-child(5) {
                        // width: 7%;
                    }
                }
            }
        }
    }

}

.edit-role-outer {
    background: #0006;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;
}

.edit-role-main {
    font-family: "Poppins",sans-serif;
    background: $white;
    border-radius: 25px 0 25px 0;
    width: 620px;
    height: 100%;
    padding: 40px;
    position: absolute;
    right: 0;
    overflow-y: auto;
    border: 0;

    h2 {
        font-weight: 500;
        font-size: 22px;
        line-height: 30px;
        color: $heading-color;
        margin-bottom: 15px;
        font-family: "Poppins",sans-serif;
    }
    a.close-icn {
        position: absolute;
        top: 10px;
        right: 20px;
        font-size: 28px;
        color: $heading-color;
        cursor: pointer;
    }
}

.user-list {
    margin: 20px 0;
    ul {
      display: flex;
      white-space: normal;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        width: calc(50% - 20px);
        display: flex;
        padding: 10px 0;
        height: 46px;
      }
    }
    &.assign-featue-list {
      li {
        width: 100%;
        padding: 15px 0;
        border-bottom: 1px solid $border-color;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .feature-type-header {
        font-size: 16px;
        font-weight: 600;
        color: $heading-color;
        margin: 10px 0;
      }
    }
    .custom-control-label::before {
      top: 2px;
    }
    .custom-control-label::after {
      top: 2px;
    }
  }

  .ToggleSwitch {
    position: relative;
    // width: 5rem;
    // height: 2.35rem;
    // display: inline-block;

    &.ToggleSwitch__rounded {
        .Slider {
            border-radius: 15rem;
            background: #eceff1;
            border: 1px solid #a0a0a0;

            &:before {
                border-radius: 50%;
            }
        }
    }

    .ToggleSwitch__wrapper {
        position: relative;
        width: 35px;
        height: 18px;

        .Slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: 0.4s ease;

            &:before {
                width: 16px;
                height: 16px;
                position: absolute;
                background: #fff;
                content: "";
                margin: 0;
                padding: 0;
                top: 50%;
                left: 0.1rem;
                transform: translateY(-55%);
                transition: 0.4s;
                cursor: pointer;
                // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            }

            &.isChecked {
                background: #0bbddd;
                border-color: #0bbddd;

                &:before {
                    left: calc(100% - 0.1rem - 1rem);
                    background: #fff;
                }
            }

            &.disabled {
                background: #ccc !important;
                border-color: #ccc !important;
                cursor: not-allowed;

                &:before {
                    cursor: not-allowed;

                }
            }
        }
    }
}

.edit-role-main a.close-icn {
    color: #000;
    cursor: pointer;
    font-size: 28px;
    position: absolute;
    right: 20px;
    top: 10px;
    opacity: inherit;
}

.custom-checkbox .custom-control-input:checked ~ .user-permission {
    display: flex;
  }


.feature-assign-heading span {
    color: #000;
    position: relative;
    text-decoration: underline;
}

.feature-assign-heading span:after {
    bottom: -3px;
    content: "";
    font-family: dc !important;
    font-size: 27px;
    margin-right: 10px;
    position: absolute;
}


.userList-feature-assign {
    ul {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 16px;
        li {
            color: #000000b3;
            display: flex;
            font-size: 12px;
            line-height: normal;
            padding-bottom: 12px;
            width: calc(50% - 20px);
            
            &::before {
                content: "";
                display: inline-block;
                font-family: dc !important;
                margin-right: 10px;
            }
        }
    }
} 
 
