.block,
.d-block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}
.float-left {
  float: left;
}

.float-right {
  float: right;
}

.p-rel {
  position: relative;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: $overlay-color;
  overflow: hidden;
}

.o-hidden {
  overflow: hidden;
}

.v-middle {
  vertical-align: middle;
}

.error {
  display: block;
  margin-top: 4px;
}

.valid {
  color: $color-valid;
  display: block;
  margin-top: 4px;
}

.icon-tick:before {
  color: $color-valid;
}
.hidden {
  opacity: 0;
  height: 0;
  pointer-events: none;
  overflow: hidden;
  margin-bottom: 0;
}

.bg-white {
  background: #fff;
}

.bg-light {
  background: #f1f6fa;
}

.card-curved {
  background: #fff;
  border-radius: 24px 24px 0 0;
  -moz-border-radius: 24px 24px 0 0;
  -webkit-border-radius: 24px 24px 0 0;
  -ms-border-radius: 24px 24px 0 0;
}
.border-bottom {
  border-bottom: 1px solid $border-color;
}

.border-top {
  border-top: 1px solid $border-color;
}

.border {
  border: 1px solid $border-color;
}

.hide {
  display: none;
}

.pointer {
  cursor: pointer;
}

.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}


.error-txt {
  bottom: -17px;
  color: #ff2e2e;
  font-size: 10px;
  left: 0;
  position: absolute;
}