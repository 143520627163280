.datepicker {
    input {
        width: 100%;
        border: 1px solid #bbbbbb;
        border-radius: 8px;
        box-shadow: 0 0 0;
        width: 100%;
        padding: 0px 15px;
        font-weight: 400;
        font-size: 12px;
        background: url(../../images/date.svg) no-repeat right 7px center;
        height: 54px;
    }
    .react-datepicker__header__dropdown {
        padding: 10px;
        select {
            appearance: auto;
            background: transparent;
            border: 0px;
        }
    }
    .react-datepicker-wrapper{
        background: $white;
    }
}

.timepicker-filed {
    input {
        width: 100%;
        border: 1px solid #bbbbbb;
        border-radius: 8px;
        box-shadow: 0 0 0;
        width: 100%;
        padding: 11px 15px;
        font-weight: 400;
        font-size: 12px;
        background: url(../../images/time_icn.svg) no-repeat right 7px center;
    }
    .react-datepicker__header__dropdown {
        padding: 10px;
        select {
            appearance: auto;
            background: transparent;
            border: 0px;
        }
    }
}

.timepicker-filed {
    .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after,
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
        left: -18px !important;
    }
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: #0bbddd !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: #0bbddd !important;
}

.datepick-heading {
    &:before {
        // top: -9px !important;
        font-size: 12px;
        background: #ffffff;
    }
}