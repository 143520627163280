.upload-file-outer{

    .image-uploader-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px 0 0;
        .display-box {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // background-color: #eff2f9;
            min-height: 323px;
            width: 100%;
            padding-top: 40px;
            border-radius: 8px;
            border: 1px dashed #9CA2C5;
            &:hover,
            &.drag-over {
                .icon-text-box {
                    // background-color: #96a599;
                    cursor: pointer;
                    // color: #fff;
                    // text-shadow: 0px 1px 0 #999;
                    .upload-icon {
                        // transform: translateY(-20px);
                        // animation: bounce 1s ease-in-out infinite;
                    }
                }
            }
            .icon-text-box {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                text-shadow: 0px 0px 0 #999;
                transition: background-color 0.2s ease-in, color 0.2s ease-in, border-color 0.2s ease-in,
                    text-shadow 0.2s ease-in;
                .upload-icon {
                   
                    margin: 0;
                    box-sizing: border-box;
                    transform: translateY(0px);
                    transition: transform 0.2s ease-in-out;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    i {
                        font-size: 38px;
                    }
                }
                .upload-button,
                .cancel-upload-button {
                    margin: 0 10px;
                    position: relative;
                    z-index: 9999;
                }
    
            }
            .upload-image-input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }
        }
    }
    .upload-txt {
        padding-top: 24px;
        h4 {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: $heading-color;
        }
        span {
            font-weight: normal;
            font-size: 12px;
            line-height: 22px;
            color: rgba($heading-color, 0.5);
            padding: 0px 50px;
            display: block;
        }
        .or-txt {
            text-transform: uppercase;
            padding-top: 20px;
            font-weight: normal;
            font-size: 12px;
            line-height: 22px;
            color: $heading-color;
        }
        .error-msg {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            color: #e02020;
            opacity: 1;
            display: block;
            padding: 5px 50px;
        }
    }
    
    .file-btn {
        min-width: 177px;
        box-shadow: none;
        margin: 23px 0px 30px;
    }
    .upload-again-btn {
        margin-top: 15px;
    }
    
    .upload-file-name {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding-top: 20px;
    
        span.suceess-icn {
            height: 18px;
            width: 18px;
            background: #44d7b6;
            border-radius: 100%;
            color: #fff;
            font-size: 13px;
            font-weight: bold;
            margin-right: 5px;
        }
        h4 {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: $heading-color;
            padding-left: 5px;
        }
        .ic-clearclose {
            background: white;
            height: 20px;
            width: 20px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
        }
        .upload-button {
            display: none;
        }
    }
    }

    .tab-line {
        &.nav-pills {
            width: 100%;
            margin-left: 0px;
            padding: 0px;
            box-shadow: none;
            background: transparent;
            .nav-item {
                a {
                    background: transparent;
                    padding: 0px;
                    height: auto;
                    border-bottom: 2px solid transparent;
                    border-radius: 0px;
                    color: rgba($heading-color, 0.5);
                    margin-right: 35px;
                    padding-bottom: 3px;
                    font-size: 14px;
                    &.active {
                        border-color: #0bbddd;
                        color: #0bbddd;
                        font-weight: 500;
                        background: transparent;
                    }
                }
                &:last-child {
                    a {
                        margin-right: 0px;
                    }
                }
            }
            .tab-list {
                position: relative;
                width: 92%;
                overflow: auto;
                &:after {
                    content: "";
                    background: rgba(0, 0, 0, 0.1);
                    height: 1px;
                    width: 100%;
                    position: absolute;
                    bottom: 0px;
                }
            }
            .config-name-row {
                width: 92%;
                overflow-x: scroll;
                white-space: nowrap;
            }
            ::-webkit-scrollbar {
                height: 0px;
                width: 0px;
            }
        }
    }



    .nav-pills {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .nav-item {
            a {
                background: #eff2f9;
                border-radius: 100px;
                font-weight: normal;
                font-size: 12px;
                line-height: 20px;
                color: $heading-color;
                height: 36px;
                padding: 0px 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                cursor: pointer;
                &.active {
                    background: #0bbddd;
                    color: $white;
                }
            }
        }
    }
    .tab-content {
        .tab-pane {
            display: none;
            &.activem, &.active_tab {
                display: block;
            }
        }
    }