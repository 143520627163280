.rule-engine-pages {


    .top-heading {
        padding: 18px 0;
        display: block;

        h1 {
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
            color: $heading-color;
        }
    }

    .loan-filter-bg {
        padding: 0px 0;

        .top-filter-bg {
            background: $white;
            // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
            border-radius: 8px;
            padding: 20px;
            margin-bottom: 20px;
        }

        .lead-list-filter {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .flex-column {
            .tab-list {
                display: flex;
                align-items: center;
                padding: 25px 20px 18px;
                background: $white;
                border-radius: 8px 8px 0 0;

                &:after {
                    display: none;
                }
            }
        }

        .css-1pcexqc-container {
            input {
                appearance: auto;
                -webkit-appearance: auto;
                -moz-appearance: auto;
            }
        }

        .more-filter {
            // display: flex;
            // align-items: center;
            // color: $heading-color;
            margin: 0px 0px 0px 10px;

            .more-filter-txt,
            .leass-filter-txt {
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                margin-left: 5px;
                white-space: nowrap;
                margin-right: 20px;
            }

            .dropdown {
                display: flex;
                position: relative;

                .dropdown-toggle {
                    display: flex;
                    align-items: center;
                    background: transparent;
                    border: 0px;

                    &::before {
                        content: "\e907";
                        font-family: dc !important;
                    }
                }

                .leass-filter-txt {
                    display: none;
                }

                .dropdown-menu {
                    box-shadow: 0px 2px 14px rgba(31, 45, 78, 0.14);
                    border-radius: 6px;
                    width: 554px;
                    padding: 30px;
                    background: $white;
                    // margin-top: 33px;
                    z-index: 99;
                }

                &.show {
                    .dropdown-menu {
                        display: block;
                        position: absolute !important;
                        // right: 0px !important;
                        top: 18px !important;
                        transform: translate(-44%, 40px) !important;

                        // margin-top: 33px;
                    }

                    .dropdown-toggle {
                        &::before {
                            content: "\e90c";
                            font-family: dc !important;
                        }
                    }

                    .leass-filter-txt {
                        display: block;
                    }

                    .more-filter-txt {
                        display: none;
                    }
                }
            }

            .more-filter-option {
                h3 {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    color: $heading-color;
                    display: block;
                    margin-bottom: 16px;
                }

                .filter-form {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;

                    fieldset {
                        width: 50%;
                        width: 48%;
                        margin-right: 0px;
                    }
                }
            }
        }

        // fieldset {
        //     width: 16%;
        //     margin-right: 8px;
        //     margin-bottom: 10px;
        // }

    }

    .search-bx {
        .material {
            .form-input {
                padding-left: 45px;
            }

            i {
                position: absolute;
                left: 15px;
                font-size: 20px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .multiselect-dropDown {

        .css-1r4vtzz,
        .css-48ayfv {
            height: 42px;
            border: 1px solid #bbbbbb !important;
            border-radius: 8px !important;
            background: transparent;
            width: 100%;
            box-shadow: none;
            padding-left: 15px;
        }

        .css-1jllj6i-control {
            // width: 100%;

            box-shadow: none;
        }

        .css-6y1x9t-control {
            border-color: hsl(0, 0%, 80%) !important;
            box-shadow: none;

            &:hover {
                border-color: hsl(0, 0%, 80%) !important;
                box-shadow: none;
            }
        }

        .css-1v99tuv,
        .css-11unzgr {
            font-size: 12px;
            color: $heading-color;
        }

        input {
            appearance: auto;
        }

        .css-1qprcsu-option {
            font-weight: normal;
        }

        .css-1gpjby2 {
            svg {
                display: none;
            }

            &:after {
                content: "\e917";
                position: absolute;
                right: 5px;
                color: #000;
                font-family: dc !important;
                font-size: 24px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .css-1v99tuv {
            position: relative;
            top: -1px;
        }

        .css-48ayfv+div {
            box-shadow: 0 0 0 1px rgba(255, 255, 255, 1), 0 4px 11px rgba(0, 0, 0, 0.1);
        }
    }

    .btn-submit-reset {
        display: flex;
        align-items: center;

        .btn-primary {
            min-width: 98px;
            margin-right: 10px;
        }

        .btn-reset {
            background: transparent;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            border: 0px;
            color: $heading-color;
        }

        &.more-filter-btn {
            margin-top: 20px;

            .btn-primary {
                min-width: 171px;
                margin-right: 10px;
            }
        }
    }

    .datepicker {
        input {
            width: 100%;
            border: 1px solid #bbbbbb;
            border-radius: 8px;
            box-shadow: 0 0 0;
            width: 100%;
            padding: 11px 15px;
            font-weight: 400;
            font-size: 12px;
            background: url(../../images/date.svg) no-repeat right 7px center;
        }

        .react-datepicker__header__dropdown {
            padding: 10px;

            select {
                appearance: auto;
                background: transparent;
                border: 0px;
            }
        }
    }

    .timepicker-filed {
        input {
            width: 100%;
            border: 1px solid #bbbbbb;
            border-radius: 8px;
            box-shadow: 0 0 0;
            width: 100%;
            padding: 11px 15px;
            font-weight: 400;
            font-size: 12px;
            background: url(../../images/time_icn.svg) no-repeat right 7px center;
        }

        .react-datepicker__header__dropdown {
            padding: 10px;

            select {
                appearance: auto;
                background: transparent;
                border: 0px;
            }
        }
    }

    .timepicker-filed {

        .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
        .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
        .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after,
        .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
            left: -18px !important;
        }
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        background-color: #0bbddd !important;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
        background-color: #0bbddd !important;
    }

    .filter-form {
        .filter-heading {
            margin-left: 0px;
            padding: 20px 0 10px;
            display: block;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $heading-color;
            width: 100%;
        }

        fieldset {
            width: 175px;
            margin-right: 8px;
        }

        .Date-picker-sec {
            margin-top: 10px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        .follow-up-sec {
            margin-top: 0px;
        }

        .css-g1d714-ValueContainer {
            padding-left: 14px;
        }

        .css-1jllj6i-control {
            min-width: auto !important;
        }

        .css-1vr111p-option {
            min-width: auto !important;
        }

        .css-48ayfv+div {
            width: 100%;
        }

        .css-6y1x9t-control,
        .css-1qprcsu-option {
            min-width: auto !important;
        }
    }

    .more-filter-option {
        .css-1gpjby2 {
            svg {
                display: none;
            }

            &:after {
                content: "\e917";
                position: absolute;
                right: 3px;
                color: #000;
                font-family: dc !important;
                font-size: 24px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .single-select {
        .css-1wa3eu0-placeholder {
            font-size: 13px;
            color: rgba($heading-color, 0.6) !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: calc(100% - 20px);
        }

        .css-1hb7zxy-IndicatorsContainer {
            svg {
                display: none;
            }
        }

        .css-1okebmr-indicatorSeparator,
        .css-109onse-indicatorSeparator {
            display: none;
        }

        .css-1pahdxg-control,
        .css-yk16xz-control {
            height: 42px;
            border-radius: 8px !important;
            outline: none !important;
            box-shadow: none;
            border-color: #bbbbbb !important;

            &:hover {
                border-color: #bbbbbb !important;
                box-shadow: none;
                border-radius: 8px;
                outline: none !important;
            }
        }

        .css-1hb7zxy-IndicatorsContainer {
            &:after {
                content: "\e917";
                position: absolute;
                right: 3px;
                color: #000;
                font-family: dc !important;
                font-size: 24px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .css-1uccc91-singleValue,
        .css-1v99tuv {
            font-size: 12px;
            font-weight: 500;
            color: $heading-color;
        }

        .css-4ljt47-MenuList,
        .css-11unzgr {
            font-size: 12px;
            color: $heading-color;
        }

        .css-1qprcsu-option,
        .css-1vr111p-option {
            font-weight: normal;

            input {
                position: relative;
                top: 3px;
            }
        }

        .css-1uccc91-singleValue {
            max-width: calc(100% - 20px) !important;
        }
    }

    .show.dropdown .top-btn-none {
        opacity: 0;
    }

    .search-filter-result-count {
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: $heading-color;
        display: block;
        width: 100%;
        padding: 20px 20px 10px;
        background: #eff2f9;
    }

    .lead-list-outer {

        .filter-filed-form {
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .search-bx {
                input {
                    height: 42px !important;
                }
            }
        }
    }

    .more-filter-dropdown {
        margin-top: 0px !important;

        fieldset {
            margin-bottom: 10px;
        }
    }

    .selected-chips {
        ul {
            display: flex;
            align-items: center;
            padding: 20px 0 0 0;

            li {
                background: $white;
                padding: 6px 6px 6px 17px;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                color: $heading-color;
                display: flex;
                align-items: center;
                border-radius: 16px;
                margin-right: 8px;
                border: 1px solid #dde1e9;

                span {
                    background: rgba(227, 231, 241, 0.6);
                    height: 20px;
                    width: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 9px;
                    border-radius: 12px;
                }
            }
        }
    }

    .model-chips {
        margin-top: 16px;

        .chip-heading {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            display: block;
        }

        .chip-control {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            cursor: pointer;
            font-size: 13px;
            text-align: center;
            margin: 8px 8px 0px 0;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;

            input {
                position: absolute;
                z-index: -1;
                opacity: 0;
                top: 0;
            }

            span {
                display: block;
                padding: 8px 20px;
                border-radius: 100px;
                background: #fff;
                font-size: 12px;
                color: #24272c;
                vertical-align: top;
                transition: background .2s;
                border: 1px solid $border-color;

            }

            input:checked~span {
                border-color: $accent-color;
            }
        }
    }

}

.filter-filed-form-template {
    display: flex;
    align-items: center;
.btn-submit-reset {
    margin-bottom: 20px;
    .btn-line, .btn-primary{
        height: 54px;
        margin-right: 10px;
    }
}
}


.top-heading {
    display: block;
    padding: 18px 0;
}

.back-opt {
    padding-top: 20px;
}

.btn-submit-reset .btn-reset {
    background: #0000;
    border: 0;
    color: #000;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
}