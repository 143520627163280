.top-heading-outer {
    background: #fff;
    padding: 0 15px;

    .sub-heading {
        border-bottom: 3px solid #0bbddd;
        font-weight: 600;
        color: rgba(11, 189, 221, .87);
        display: inline-block;
    }
}

.financier-detail-outer {
    background: #fff;
    padding: 15px;
    margin-top: 20px;
    border-radius: 8px;

    .financier-top-filter {
        display: flex;
        align-items: center;
    }

    fieldset {
        width: 20%;
        margin-right: 10px;
        display: inline-block;
    }

    .btn-submit-reset {
        display: flex;
        align-items: center;
        display: inline;
    }

    .financier-sub-filter-list {
        margin-top: 40px;
    }

    .swith-txt-outer {
        padding: 30px 0 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
            font-weight: 600;
            color: #000;
        }

        .toggle-switch-outer {
            width: 150px;

            .ToggleSwitch {
                background-color: #dbdfea;
                height: 18px;
                width: 35px;
                border-radius: 55px;
            }

            .toggleApp {
                margin: auto auto auto 10px;
            }

            .ToggleSwitch.active {
                background-color: #0bbddd;
            }

            .knob {
                position: relative;
                width: 16px;
                height: 16px;
                background: #fff;
                border-radius: 50%;
                left: 0;
                transition: left .3s ease-out;
                top: 1px;
            }

            .knob.active {
                left: 18px;
                background: #fff
            }

            .toggle-status {
                margin-left: 45px;
                margin-top: -16px;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }

    .templete-list-filed-outer {
        display: flex;
        align-items: center;

        .list-left-panel {
            padding: 20px;
            border: 1px solid rgba(0, 0, 0, .06);
            width: 25%;
            max-height: 400px;
            overflow-y: auto;

            h2 {
                font-weight: 500;
                font-size: 15px;
                padding: 0;
                margin: 0;
            }

            ul {
                padding-bottom: 10px;

                li {
                    padding-left: 5px;
                    line-height: 28px;
                    cursor: pointer;
                    font-size: 13px;

                }
                .selected-attribute {
                    border-radius: 2px;
                    background-color: #0bbddd;
                    color: #fff;
                    font-weight: 600;
                }
            }
        }

        .add-remove-arrow {
            padding: 0 20px;

            li {
                width: 42px;
                height: 42px;
                text-align: center;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 5px;
                font-size: 30px;
                border: 1px solid rgba(0, 0, 0, .06);
            }


        }

        .text-filed-select-area {
            width: 67%;

            textarea {
                width: 100%;
                height: 400px;
                border: 1px solid rgba(0, 0, 0, .06);
                resize: none;
                padding: 20px;
            }
        }
    }

    .update-btn {
        padding-top: 20px;
        justify-content: center;
    }

    .btn-submit-reset {
        display: flex;
        align-items: center;
    }
}