
.approve-status-tabs {
    margin-top: 10px;
}


.truncate {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}

.active-status,
.approve-status,
.inactive-status,
.reject-status,
.paid-status,
.pending-status {
    &::before {
        content: '';
        width: 8px;
        height: 8px;
        background: #44D7B6;
        display: inline-block;
        border-radius: 100%;
        position: relative;
        top: -1px;
        margin-right: 3px;

    }
}

.inactive-status,
.reject-status {
    &::before {
        background: #E02020;
    }

    color: #E02020;
}

.paid-status {
    &::before {
        background: #3784F8;
    }
}

.pending-status {
    &::before {
        background: #FFA340;
    }
}

.txt-value-input {
    background: #ffffff;
    border: 1px solid #bbbbbb;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 5px 10px;
    width: 90px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.info-icn {
    display: inline-block;
    position: relative;

    .info-text-detail {
        display: none;
        background: #1F1F1F;
        border-radius: 5px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $white;
        padding: 5px 15px;
        position: absolute;
        top: -33px;
        left: -23px;

        &::after {
            content: "\e922";
            font-family: dc !important;
            position: absolute;
            bottom: -12px;
            color: $heading-color;
            font-size: 40px;
            left: 0px;
            right: 0px;
            margin: 0 auto;
            text-align: center;

        }
    }

    &:hover {
        .info-text-detail {
            display: block;
        }
    }

    .ic-info-icon1 {
        top: 2px;
        position: relative;
    }
}



.hightlighted-txt {
    background: #E7FBFF;
    border: 1px solid #4E4E4E;
}


.tooltip {
    position: relative;
    float: right;
}

.tooltip>.tooltip-inner {
    background-color: #eebf3f;
    padding: 5px 15px;
    color: rgb(23, 44, 66);
    font-weight: bold;
    font-size: 13px;
}




.progress {
    border-radius: 0;
    overflow: visible;
}



.approve-color {
    color: #59B74A;
}

.rejected-color {
    color: #FF3636;
}

.no-data {
    text-align: center !important;
    padding-bottom: 20px !important;
    color: rgba(0, 0, 0, 0.6) !important;
    font-size: 12px !important;
}



.wordWrap {
    overflow-wrap: break-word;
}

.resize {
    resize: none;
}



.hidden {
    opacity: 0;
}


.dashboard-outer {
    max-width: 1114px;
    margin: 0 auto;
    .heading {
        color: $heading-color;
        text-align: center;
        padding-top: 50px;
        h1 {
            font-weight: 600;
            font-size: 42px;
            line-height: 63px;
        }
        .subheading {
            font-weight: normal;
            font-size: 18px;
            line-height: 27px;
        }
    }
    .search-bx {
        position: relative;
        max-width: 710px;
        margin: 0 auto;
        margin-top: 24px;
        margin-bottom: 40px;
        input {
            background: $white;
            border-radius: 100px;
            width: 710px;
            padding: 10px 135px 10px 70px;
            height: 70px;
            border-color: #eff2f9;
            font-size: 15px;
            color: rgba($heading-color, 0.7);
        }
        .search-btn {
            background: #008CF0;
            border-radius: 0px 100px 100px 0px;
            height: 70px;
            font-weight: normal;
            font-size: 15px;
            line-height: 22px;
            color: $white;
            border: 1px solid #0bbddd;
            display: inline-block;
            width: 131px;
            position: absolute;
            right: 0px;
            cursor: pointer;
        }
        .ic-search {
            font-size: 27px;
            position: absolute;
            left: 28px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .dashboard-card {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        box-shadow: 0px 2px 4px 0px #0000000A;
        padding: 30px 20px;
        background-color: $white;
        border-radius: 10px;
        width: 792px;
        .card {
            border-radius: 15px;
            padding: 20px;
            width: 168px;
            height: 216px;
            margin: 20px 10px 0 10px;
            cursor: pointer;
            border: 1px solid #E3E3E3;
            .roung-bg {
                height: 52px;
                width: 52px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin: 0 auto;
                i {
                    font-size: 20px;
                    color: #000;
                }
            }
        }
        .color-one {
            background: #bff8fc;
        }
        .color-two {
            background: #fff1d6;
        }
        .color-three {
            background: #ffece2;
        }

        .color-four {
            background: #d2fceb;
        }
        .color-five {
            background: #d8eaff;
        }
        .color-six {
            background: #ffdede;
        }

        .count-txt {

            font-size: 34px;
            font-weight: 500;
            line-height: 51px;
            letter-spacing: 0.5px;
            text-align: center;
            color: $heading-color;
            display: block;
            text-align: center;
            padding: 8px 0 0;
        }
        .card-txt {
            font-weight: normal;
            font-size: 13px;
            line-height: 21px;
            text-align: center;
            display: block;
            color: $heading-color;
        }
        .clickable-card {
            // color: #0bbddd;
            cursor: pointer;
        }
    }
    ::placeholder {
        font-size: 15px;
        color: rgba($heading-color, 0.7);
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-size: 15px;
        color: rgba($heading-color, 0.7);
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        font-size: 15px;
        color: rgba($heading-color, 0.7);
    }
}

footer {
    padding: 20px 0;
    .footer-txt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .copyright-txt {
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: rgba($heading-color, 0.4);
        }
    }
    .footer-link {
        display: flex;
        align-items: center;
        li {
            padding-left: 30px;
            position: relative;
            a {
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                color: rgba($heading-color, 0.4);
            }
            &:first-child {
                padding-right: 30px;
                &::after {
                    content: "";
                    width: 1px;
                    height: 17px;
                    background: rgba(0, 0, 0, 0.2);
                    position: absolute;
                    right: 0px;
                    top: 2px;
                }
            }
        }
    }
}

.dashboard-bg {
    background: #ECF1F8;
    padding-bottom: 100px;
}


.action-btn {
    display: flex;
    align-items: center;

    li {
        height: 32px;
        width: 32px;
        min-width: 32px;
        border-radius: 4px;
        border: 1px solid #e0e0e0;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        cursor: pointer;
        color: $heading-color;

        .ic-email1 {
            font-size: 22px;
        }

        .ic-otp {
            font-size: 23px;
        }
    }
}
.dashboard-notification-outer{
    justify-content: space-between;
}

.doc-notification{
    background: $white;
    box-shadow: 0px 2px 4px 0px #0000000A;
    border-radius: 10px;
    padding: 20px;
    width: 301px;
    margin-left: 20px;
    h2{
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        color: $heading-color;
        margin-bottom: 30px;

    }
   
}

.notification-hisorty-list{
        max-height: 680px;
        overflow-y: auto;
        margin: 0px -20px;
        li{
            color: $heading-color;
            border-bottom: 1px solid rgba($heading-color, 0.1);
            padding: 20px;
            &:first-child{
                padding-top: 0px;
            }
            &:last-child{
                border-bottom: 0px;
            }
            h3{
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
                
            }
            .notification-msg{
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                text-align: left;
                display: block;
                padding: 10px 0;
            }
            .time-date{
                font-size: 10px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: #666666;
                display: block;
            }
        }
    }

.notification-history-claimlist {
    max-height: 432px !important;
}